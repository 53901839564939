<template>
  <v-col class="mt-6">
    <v-row
      style="height: fit-content"
      class="d-flex flex-column flex-sm-row"
    >
      <v-card
        class="d-flex flex-column pa-6"
        style="border: 1px solid; flex: 1"
        max-width="400px"
        :style="{borderColor: $vuetify.theme.themes.light.border}"
        elevation="0"
      >
        <div class="d-flex align-center">
          <v-icon
            class="mr-2"
            color="neutralPrimary"
            :size="$vuetify.breakpoint.mobile? 20 : 24"
          >
            {{ icons.tagMultiple }}
          </v-icon>
          <span
            class="subtitle-1 font-weight-bold neutralPrimary--text"
          >
            {{ $t('deconve.tags') }}
          </span>
        </div>
        <div class="d-flex flex-wrap pt-1 responsiveTag">
          <tag
            v-for="tag in tags"
            :key="tag.id"
            class="pr-1 pt-1"
            :tag-id="tag.id"
          />
        </div>
      </v-card>
      <div style="width: 12px; height: 12px" />
      <div style="flex: 1;">
        <v-card
          class="d-flex flex-column pa-6"
          width="100%"
          height="200px"
          style="border: 1px solid"
          :style="{borderColor: $vuetify.theme.themes.light.border}"
          elevation="0"
        >
          <div class="d-flex align-center">
            <v-icon
              class="mr-2"
              color="neutralPrimary"
              :size="$vuetify.breakpoint.mobile? 20 : 24"
            >
              {{ icons.noteOutline }}
            </v-icon>
            <span
              class="subtitle-1 font-weight-bold neutralPrimary--text"
            >
              {{ $t('deconve.about') }}
            </span>
          </div>
          <v-textarea
            v-model="about"
            class="body-2 text-sm-body-1 mt-0 pt-2"
            disabled
          />
        </v-card>
      </div>
    </v-row>
  </v-col>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapGetters } from 'vuex';
import Tag from '@/components/Tag.vue';
import { mdiNoteOutline, mdiTagMultipleOutline } from '@mdi/js';

export default {
  name: 'PersonShareInfo',
  components: {
    Tag,
  },
  props: {
    personId: { type: String, required: true },
  },
  data() {
    return {
      isLoading: false,
      tags: [],
      about: '',
      icons: {
        noteOutline: mdiNoteOutline,
        tagMultiple: mdiTagMultipleOutline,
      },
    };
  },
  computed: {
    ...mapGetters({
      getPerson: 'faceid/getPerson',
    }),
    person() {
      const personInfo = this.getPerson(this.personId);

      return personInfo;
    },
  },
  watch: {
    person() {
      this.getPersonSharingStats();
    },
  },
  created() {
    this.getPersonSharingStats();
  },
  methods: {
    getPersonSharingStats() {
      this.isLoading = true;

      const currentReview = this.person?.sharing?.current_review;

      if (currentReview) {
        const { tags, about } = currentReview;

        this.tags = tags;
        this.about = about;
      } else {
        this.tags = [];
        this.about = '';
      }

      this.isLoading = false;
    },
  },
};
</script>

<style>
  textarea {
    resize: none;
    height: 200px;
  }

  @media (max-width: 300px) {
    .responsiveTag{
      justify-content: center !important;
      margin-right: -26px !important;
    }
    .responsiveButton {
      flex-direction: column !important;
    }
  }
</style>
