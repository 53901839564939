<template>
  <v-col v-if="!isLoading">
    <v-row
      v-if="!isLoading"
      align="center"
      justify="space-between"
      style="height: 64px"
    >
      <div
        class="d-flex align-center"
      >
        <v-icon
          class="mr-2"
          color="neutralPrimary"
          :size="$vuetify.breakpoint.mobile? 20 : 24"
        >
          mdi-file-video-outline
        </v-icon>
        <div
          class="subtitle-1 font-weight-bold neutralPrimary--text"
        >
          {{ personVideos.length }} {{ $t('deconve.videos') }}
        </div>
      </div>
    </v-row>
    <v-row>
      <file-item
        v-for="newFile in personVideos"
        :key="newFile.originalName"
        :name="newFile.originalName"
        class="pa-1"
        @open="download(newFile.originalName)"
      />
    </v-row>
    <v-snackbar
      v-model="enableAlert"
      color="error"
    >
      {{ $t('deconve.fileUrlError') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="enableAlert = false"
        >
          <v-icon color="white">
            {{ icon.close }}
          </v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-col>
  <v-col v-else>
    <v-row class="my-2">
      <v-skeleton-loader
        type="heading, list-item"
        style="flex: 1"
        max-width="500"
      />
    </v-row>
  </v-col>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapActions, mapGetters } from 'vuex';
import { mdiClose } from '@mdi/js';

import FileItem from '../../../components/FileItem.vue';

export default {
  name: 'ProfileVideos',
  components: {
    FileItem,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    personId: { type: String, required: true },
  },
  data() {
    return {
      enableAlert: false,
      icon: {
        close: mdiClose,
      },
    };
  },
  computed: {
    ...mapGetters({
      getVideoUrl: 'faceid/getVideoUrl',
      personVideos: 'faceid/personVideos',
    }),
  },
  methods: {
    ...mapActions({
      fetchPerson: 'faceid/fetchPerson',
    }),
    download(videoName) {
      // To update the video url
      this.fetchPerson(this.personId).then(() => {
        const videoUrl = this.getVideoUrl(videoName);

        if (videoUrl) {
          window.open(videoUrl);
        } else {
          this.enableAlert = true;
        }
      });
    },
  },
};
</script>
