<template>
  <v-container>
    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="referenceUnits"
      :loading="isLoading"
      :search="search"
      no-data-text=""
      no-results-text=""
      hide-default-footer
      disable-pagination
    >
      <template v-slot:header>
        <div
          dense
          class="d-flex align-center justify-space-between mb-6"
        >
          <input-text
            v-model="search"
            max-width="240px"
            left-icon="mdi-magnify"
            :placeholder="$t('deconve.search')"
          />
          <v-spacer />
          <square-button
            icon-name="mdi-information-outline"
            :icon-color="showUnitsInfo ? 'white' : 'neutral'"
            :color="showUnitsInfo ? 'info' : 'transparentBackground'"
            menu-disabled
            :tooltip-message="$t('deconve.info')"
            :outlined="!showUnitsInfo"
            fab
            small
            @clicked="showUnitsInfo = !showUnitsInfo"
          />
        </div>
      </template>

      <template v-slot:default="props">
        <v-col>
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />
          <v-row
            align="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="subtitle-1 font-weight-bold neutralPrimary--text"
            >
              {{ $t('deconve.referenceUnits') }}
            </div>
          </v-row>
          <v-row
            v-if="showUnitsInfo"
            class="mb-4"
          >
            <alert
              type="info"
              color="info"
            >
              <div>
                {{ $t('deconve.referenceUnitsInfo') }}
              </div>
            </alert>
          </v-row>
          <v-row>
            <v-col
              v-for="unit in props.items"
              :key="unit.id"
              :cols="isMobileVersion ? 'auto' : 3"
            >
              <v-card
                elevation="2"
                class="pa-1 mr-2 mb-2"
                width="300px"
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <span class="text-subtitle-2 mb-6">
                      {{ unit.name ? unit.name : unit.id }}
                    </span>
                    <div class="d-flex justify-space-between">
                      <v-list-item-subtitle style="max-width: 250px">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                v-if="!isMobileVersion"
                                dense
                              >
                                {{ icons.calendarRange }}
                              </v-icon>
                              <span>
                                {{ unit.created_at }}
                              </span>
                            </span>
                          </template>
                          <span>
                            {{ $t('deconve.monitoringStartDate') }}
                          </span>
                        </v-tooltip>
                      </v-list-item-subtitle>

                      <v-list-item-subtitle style="max-width: 50px">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon
                                dense
                                color="#54D162"
                              >
                                {{ icons.check }}
                              </v-icon>
                              <span>
                                {{ unit.number_of_positive_notification }}
                              </span>
                            </span>
                          </template>
                          <span>
                            {{ $t('deconve.numberOfPositiveNotifications') }}
                          </span>
                        </v-tooltip>
                      </v-list-item-subtitle>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </template>
    </v-data-iterator>

    <v-data-iterator
      class="d-flex flex-column"
      style="height: 100%"
      :items="nearbyUnits"
      :loading="isLoading"
      :search="search"
      no-data-text=""
      no-results-text=""
      hide-default-footer
      disable-pagination
    >
      <template v-slot:default="props">
        <v-col>
          <v-progress-linear
            v-if="isLoading"
            :indeterminate="true"
          />
          <v-row
            align="center"
            justify="space-between"
            style="height: 64px"
          >
            <div
              class="subtitle-1 font-weight-bold neutralPrimary--text"
            >
              {{ $t('deconve.nearbyUnits') }}
            </div>
          </v-row>
          <v-row
            v-if="showUnitsInfo"
            class="mb-4"
          >
            <alert
              type="info"
              color="info"
            >
              <div>
                {{ $t('deconve.nearbyUnitsInfo') }}
              </div>
            </alert>
          </v-row>
          <div class="flex-wrap">
            <v-chip
              v-for="unit in props.items"
              :key="unit.id"
              class="pa-1 mr-2 mb-2"
              label
              outlined
            >
              {{ unit.name ? unit.name : unit.id }}
            </v-chip>
          </div>
        </v-col>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
// Copyright (C) 2023 Deconve Technology. All rights reserved.

import { mapActions } from 'vuex';

import moment from 'moment';

import InputText from '@/components/InputText.vue';
import SquareButton from '@/components/SquareButton.vue';
import Alert from '@/components/Alert.vue';

import { mdiCalendarRangeOutline, mdiCheck } from '@mdi/js';
import { UNITS_ADDED_TYPE } from '@/utils/unitsAddedType';

export default {
  name: 'ProfileUnits',
  components: {
    InputText,
    SquareButton,
    Alert,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    personId: { type: String, required: true },
  },
  data() {
    return {
      search: '',
      showUnitsInfo: false,
      unitIds: [],
      referenceUnits: [],
      nearbyUnits: [],
      icons: {
        calendarRange: mdiCalendarRangeOutline,
        check: mdiCheck,
      },
    };
  },
  computed: {
    getNearbyUnitsIds() {
      return this.unitIds.filter(({ added }) => added === UNITS_ADDED_TYPE.nearby);
    },
    getReferenceUnitsIds() {
      return this.unitIds.filter(({ added }) => added !== UNITS_ADDED_TYPE.nearby);
    },
    isMobileVersion() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  watch: {
    unitIds() {
      this.fetchNearbyUnits();
      this.fetchReferenceUnits();
    },
  },
  created() {
    this.fetchMonitoredUnits(this.personId).then((data) => {
      this.unitIds = data;
    });
  },
  methods: {
    ...mapActions({
      fetchUnit: 'units/fetchUnit',
      fetchMonitoredUnits: 'faceid/fetchMonitoredUnits',
    }),
    fetchNearbyUnits() {
      const promises = this.getNearbyUnitsIds.map(({ unit }) => new Promise((resolve) => {
        this.fetchUnit(unit.id)
          .then((data) => {
            resolve(data);
          });
      }));

      Promise.all(promises)
        .then((data) => {
          this.nearbyUnits = data;
        });
    },
    fetchReferenceUnits() {
      const promises = this.getReferenceUnitsIds.map(({
      // eslint-disable-next-line @typescript-eslint/camelcase
        unit, number_of_positive_notifications,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_at,
      }) => new Promise((resolve) => {
        this.fetchUnit(unit.id)
          .then((data) => {
            const referenceUnitsData = { ...data };

            // eslint-disable-next-line @typescript-eslint/camelcase
            referenceUnitsData.number_of_positive_notification = number_of_positive_notifications;

            // eslint-disable-next-line @typescript-eslint/camelcase
            referenceUnitsData.created_at = moment.utc(created_at).format('DD/MM/YYYY HH:mm');
            resolve(referenceUnitsData);
          });
      }));

      Promise.all(promises)
        .then((data) => {
          this.referenceUnits = data;
        });
    },
  },
};
</script>
